<template>
  <el-container>
    <el-header>
      <el-row class="el-row-header">
        <el-col :span="24"> 商家收款 </el-col>
      </el-row>
    </el-header>
    <el-main>
      <el-row class="el-row-value">
        <el-col
          :xs="{ span: 20, offset: 2 }"
          :sm="{ span: 12, offset: 6 }"
          :md="{ span: 10, offset: 7 }"
          :lg="{ span: 8, offset: 8 }"
          :xl="{ span: 6, offset: 9 }"
        >
          <el-input
            placeholder="请输入金额"
            v-model="totalAmount"
            @input="valueChange"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col
          class="col-value-button"
          :xs="{ span: 20, offset: 2 }"
          :sm="{ span: 12, offset: 6 }"
          :md="{ span: 10, offset: 7 }"
          :lg="{ span: 8, offset: 8 }"
          :xl="{ span: 6, offset: 9 }"
        >
          <el-button type="primary" @click="alipayPay">点击支付</el-button>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { alipayPay } from "@/api/alipay";
export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  data() {
    return {
      dialogVisible: false,
      totalAmount: "",
      vHtml: "",
      onlyValues: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."],
    };
  },
  methods: {
    valueChange(value) {
      if (!value) {
        return;
      }      
      this.totalAmount = this.handleChange(value);
    },
    handleChange(value) {
      if (!value) {
        return "";
      }
      let tempValue = "";
      let isFindPoint = false;
      let onlyValuesLen = this.onlyValues.length;
      let decimalDigits = 0;
      for (let i = 0, len = value.length; i < len; i++) {
        let lenV = onlyValuesLen;
        if (tempValue.length == 0 || isFindPoint) {
          lenV = lenV - 1;
        }
        let valueChar = value.charAt(i);
        let isFind = false;
        for (let j = 0; j < lenV; j++) {
          if (valueChar == this.onlyValues[j]) {
            isFind = true;
            if (j == onlyValuesLen - 1) {
              isFindPoint = true;
            }
            break;
          }
        }
        if (!isFind) {
          continue;
        }
        if (
          tempValue == this.onlyValues[0] &&
          valueChar != this.onlyValues[onlyValuesLen - 1]
        ) {
          tempValue = valueChar;
          isFindPoint = false;
          continue;
        }
        if (decimalDigits > 1) {
          continue;
        }
        if (isFindPoint && valueChar != this.onlyValues[onlyValuesLen - 1]) {
          decimalDigits++;
        }
        tempValue += valueChar;
      }
      return tempValue;
    },
    alipayPay() {
      let systemType = this.$route.query.systemType;
      if (!systemType) {
        systemType = "";
      }
      
      if (!this.totalAmount) {
        alert("请输入支付金额");
        return;
      }
      if (parseFloat(this.handleChange(this.totalAmount))<=0) {
        alert("请输入大于零的支付金额");
        return;
      }
      location.href = alipayPay(this.totalAmount, systemType);
    },
  },
};
</script>

<style scoped>
.el-row-header {
  margin-top: 10px;
}
.el-header {
  text-align: center;
  font-size: 20px;
}

.el-main {
  padding: 0;
}

.el-row-value {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 30px;
}

.col-value-button {
  text-align: center;
}
</style>