import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import 'element-plus/lib/theme-chalk/base.css'
import {
    ElContainer,
    ElHeader,
    ElMain,
    ElRow,
    ElCol,
    ElButton,
    ElInput
} from 'element-plus'


const app = createApp(App)
let elementPlusComponent = [
    ElContainer,
    ElHeader,
    ElMain,
    ElRow,
    ElCol,
    ElButton,
    ElInput
]
for (let i = 0, len = elementPlusComponent.length; i < len; i++) {
    app.use(elementPlusComponent[i])
}

app.use(router).mount('#app')
